// TODO: Refactor against main-frontend/auth.service.ts

// dep
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { map, share, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/compat/app';
// import { getAuth, onAuthStateChanged } from "firebase/auth";
import _ from 'lodash';

// app
import User from '../constants/firestore/user';
import Group from '../constants/firestore/group';
import { GroupService } from './group.service';
import { UserService } from './user.service';
import { SnackbarService } from './snackbar.service';
import { Messages } from '../constants/messages';
import { /*Session,*/ SESSION } from '../constants/session';
import { VerificationEmailService } from 'src/app/services/verification-email.service';

import { AuthProxyService } from './auth.proxy.service'

import { AuthUrls }  from "../constants/auth"

interface Claims {
  isMasterAdmin: boolean;
  isSuperAdmin: boolean;
  isActive: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // authState? : any 
  user? : any
  domain;
  // TODO: anonymous and gradeExternal are use-cases of main-frontend, not used on admin-frontend, 
  // remove or refactor against main-frontend
  // anonymous? : boolean
  // gradeExternal? : boolean 
  // gradeExternalUser? : User 

  user$ = new BehaviorSubject<any>({})
  userIndex$? : Observable<User> 

  private groupSource = new BehaviorSubject<Group | null>(null)

  private _accessToken : string | null = null 

  private _session : User | null = null

  // Default is claims that disallow
  private _claims : Claims = Object.freeze({
    'isActive': false,
    'isMasterAdmin': false,
    'isSuperAdmin': false
  });

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private groupService: GroupService,
    private ngZone: NgZone,
    private userService: UserService,
    private snack: SnackbarService,
    private verificationService: VerificationEmailService,
    private authProxyService: AuthProxyService,
    public dialog: MatDialog,
    ) {

    const isInIFrame = (window.location !== window.parent.location);
    if (!isInIFrame) {
      this.initSession()
    }

    fromEvent(window, 'storage').subscribe((storageEvent: any) => {
      //do what you need to do with your storageEvent
      // console.log('storage event', storageEvent)
      if (storageEvent['key'] == SESSION) 
        this.initSession()
    });

    //// Subscribe to Firestore Auth state changes
 
    // onAuthStateChanged():
    // Prior to 4.0.0, this triggered the observer when users were signed in, signed out, 
    // or when the user's ID token changed in situations such as token expiry or password change. 
    // After 4.0.0, the observer is only triggered on sign-in or sign-out.
    this.afAuth.onAuthStateChanged(async user => {
        console.debug('authState changed handler', user)
        
        if (!user)
          return

        await this.setClaims(user)

        if (!this.session)
          this.setSession(user);
        else if (this.session.gid) {
          this.checkUser(this.session.gid, this.session.uid);
        }
      }
    )

    // onIdTokenChanged():
    // Adds an observer for changes to the signed-in user's ID token, which includes sign-in, 
    // sign-out, and token refresh events. This method has the same behavior as 
    // firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0.
    // Also check:
    // https://github.com/angular/angularfire/issues/2694#issuecomment-734052171
    this.afAuth.onIdTokenChanged(async (user) => {
      // Update the accessToken for events not triggered by forceAuthRefresh
      // (e.g., when firebase-sdk refreshes it automatically in one of his non
      // angular intercepted queries)
      //
      console.debug('idTokenChanged changed handler', user)
      this.setAccessToken(user ? await user.getIdToken() : null, 'onIdTokenChanged')
    })

    // Firebase can start with a token already loaded (from cookies
    // or localstorage?)

    // console.log('token at start=', this.session.authToken  )

    window.addEventListener('storage', (ev) => this.onStorageEventFromOtherTab(ev))
  }


  onStorageEventFromOtherTab(event : StorageEvent) : void {
    // console.debug('storage', event)
    if(event.key === SESSION && (!event.newValue || !JSON.parse(event.newValue))) {
      console.debug('Detected signOut from another tab')
      this.signOut(true, true)
    }
  }

  async initSession(): Promise<void> {

    // Non-anonymous
    if (!this.session)
      return

    this.setSession(this.session); // MAP-1160 JWT: Not present before merge
    
    // // TODO: Subscription leak here?
    // this.groupService.getByDocId(this.session.gid).subscribe(async (group: Group) => {
    //   this.updateGroup(group);
    //   await this.processSubscription();
    // });

    const group = await this.groupService.getByDocId(this.session.gid).toPromise() 
    
    this.updateGroup(group)
  }

  async forceAuthRefresh() : Promise<string> {
    console.debug('forceRefresh() start, old token', this._accessToken)
    const user = firebase.auth().currentUser
    if (!user)
      throw Error("No user")

    const token = await user.getIdToken(/*forceRefresh =*/ true)
    // onIdTokenChanged will be called here synchronously

    this.setAccessToken(token, 'forceAuthRefresh')

    return token
  }


  checkUser(gid: string, uid? : string) {
    // TODO this setups a subscription on userIndex, multiple calls to checkUser will
    // register multiple (redundant) subscriptions. Probably an observer leak here.
    this.userIndex$ = this.userService.get(gid, uid).pipe(share(), map((indexUser: User) => {
      this.user$.next(indexUser);
      const user = { ...this.session, ...indexUser } as User;
      this.setSession(user);
      this.userService.updateSession(user);
      return indexUser;

    }));
    this.userIndex$.subscribe();

    // this.initSession(); // Removed on MAP-1160
  }

  setSession(user : User | firebase.User) : void {
    if (user)
      localStorage.setItem(SESSION, JSON.stringify(user));
  }

  updateGroup(group: Group) {
    this.groupSource.next(group);
  }

  /**
   * Returns the session as saved in LocalStorage
   */
  get session(): User | null {
    // 'Cache' as an instance variable for convenience
    if(!this._session) {
      const localSession = localStorage.getItem(SESSION);
      if (localSession) {
        this._session = Object.freeze(JSON.parse(localSession))
      }

      // TODO: Action on getter, BAD
      if (this._session?.email === 'external-grade@maplabs.com') {
        this.signOut()
        this._session = null
      }
    }

    /**
     * NOTE: Overriding at the sesison object level was simplest as many parts fo the codebase are referencing:
     * 
     *        auth.session.isMasterAdmin || auth.session.isSuperAdmin
     * 
     * merge _session and _claims so we can 'override' attributes in session/datastore with CLAIM/ROLE based attributes
     * This was done so that we can enforce ACL via auth claims (not FB properties) as a security mitigation
     */ 
    
    return this._session // NOTE: only return a merged session + claims IF we have a valid session!
      ? Object.freeze({...this._session, ...this._claims})
      : null;
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    const authState = await this.afAuth.signInWithEmailAndPassword(email, password)
    await this.afterLogin(authState)
    return true as const
  }


  async afterLogin(user: firebase.auth.UserCredential) {
    // TODO: A LOT of logic goes here.
    // If not registered? not paid? expired? sub-user?
    // + translate to async/await
    return firebase.auth().currentUser?.getIdToken().then(token => {
      return this.userService.getByUID(user.user.uid).toPromise()
        .then(data => data.docs[0].data()) 
        .then(async (value: User | null) => {
          if (!value) {
            this.signOut();
            // TODO: This snack really shows after the redirect to /login?
            this.snack.openError(Messages.register.USER_ALREADY_EXIST, 4000);
          } else {
            const isEmailVerified = await this.userService.fetchIsEmailVerified(value);
            this.setSession(value);
            if (!this.session) 
              return

            // TODO: Why fetch the user again?
            this.userService.getByUID(user.user.uid).pipe(map(result => {
              if (result.docs.length > 0) {
                return result.docs[0].data();
              } else
                return null;
            })).toPromise().then(u => {
              if (u) {
                this.userService.updateUser(u.gid, u.uid, { lastLogin: firebase.firestore.Timestamp.now() });
              }
            });


            if (isEmailVerified) {
              if (this.session.isMasterAdmin || this.session.isSuperAdmin) {

                value.authToken = token;
                this.setSession(value);
                if (this.session?.gid) {
                  this.checkUser(this.session.gid, this.session.uid);
                }

                this.redirectAccount() // TODO: This should be awaited before continuing?
                this.showMessageTypeAuth(this.session);
                return value
              } else {
                this.snack.openError('The account does not have permissions!', 3000);
              }
            } else {
              this.verificationService.getVerification(value.uid, value.gid).toPromise().then(view => {
                view.forEach((v: any) => {
                  if (!v) {
                    return;
                  }
                  const data = v.data()
                  if (data.emailVerified == null) {
                    this.signOut();
                    // TODO: This snack really shows after the redirect to /login?
                    this.snack.openError(Messages.register.EMAIL_VERIFIED, 4000);
                  } else {
                    if (this.session.isMasterAdmin || this.session.isSuperAdmin) {
                      this.userService.getByGidDocId(data.key2, data.key1).get().pipe(map(doc => doc.data()), take(1)).subscribe(user => {
                        if (user) {
                          user.emailVerified = firebase.firestore.Timestamp.now()
                          this.userService.altUpdate(user)
                        }
                        user.authToken = token;
                        this.setSession(user);
                        if (this.session?.gid) {
                          this.checkUser(this.session.gid, this.session.uid);
                        }

                        // TODO: This must be waited before continuing with redirectAccount?
                        this.groupService.getByDocId(this.session?.gid).toPromise().then(group => {
                          this.updateGroup(group);
                        });
                        this.redirectAccount()
                        this.showMessageTypeAuth(this.session);
                        return user;
                      });
                    } else {
                      this.snack.openError('The account does not have permissions!', 3000);
                    }
                  }
                });
              });
            }
          }
        });
    });

  }

  /**
   * The method allows us to 'remember' Auth CLAIMS swhich we can use later for 'ACL'
   * See https://firebase.google.com/docs/auth/admin/custom-claims#node.js_4
   * 
   * @param user UserInfo (from auth handlers)
   */
  private async setClaims(user) {
    if (user) {
      const tokenResult = (await user).getIdTokenResult()
      // pull out the claims we 'know' we want to keep for ACL purposes
      const { isMasterAdmin, isSuperAdmin, disabled } = (await tokenResult).claims
      this._claims = Object.freeze({
        isActive: !disabled,
        isMasterAdmin: isMasterAdmin,
        isSuperAdmin: isSuperAdmin
      });
    }
  }

  redirectAccount() {
    return this.router.navigate([AuthUrls.SUCCESS]);
  }

  showMessageTypeAuth(user: User | null) {
    this.snack.openInfo("User authentication was done by " + (user?.googleAuth ? "gmail" : "email / password"),
                       4000)
  }

  signOut(redirectToLogin = true, reloadPage=false) : void {
    this.ngZone.run(async () => {
      console.debug(`signOut(): redirectToLogin=${redirectToLogin} reloadPage=${reloadPage}`)
      if(!(redirectToLogin && reloadPage) && !await this.afAuth.currentUser) {
        console.debug('signout(): already logged out')
        return
      }

      for(const f of [(() => localStorage.removeItem(SESSION)), 
                      (() => this.afAuth.signOut())])
        try {
          await f()
        } catch (e) {
          console.error("signOut(): error", e)
        }

      if(redirectToLogin) {
        console.debug('signout(): reload') 
        await this.router.navigate([AuthUrls.LOGIN], reloadPage ? { replaceUrl: true } : undefined)
        if(reloadPage) {
          // Ensure refresh on logout to mitigate memory leaks and oversubscribed observers
          // If we don't that, some observers still run and try to make http requests
          // without valid authorization headers
          window.location.reload()
        }
      }
    })
  }

  async googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const authState = await this.afAuth.signInWithPopup(provider);
    await this.afterLogin(authState)
    return true as const
  }

  
  get headers() : { headers : HttpHeaders } {
      return { headers : new HttpHeaders({'uid' : this.session.uid || '', 
                                          'gid' : this.session.gid || '', 
                                          'Authorization' : 'Bearer ' + this._accessToken,
                                          'domain' : this.domain || ''})
      }
  }



  private setAccessToken(accessToken : string | null, debugStr : string) {
    this._accessToken = accessToken    
    console.debug(`accessToken changed ON ${debugStr}`, accessToken)
    if(accessToken && !this.authProxyService.initialized)
      this.authProxyService.initialize(() => this.signOut(true, true), 
                                       () => this.headers, 
                                       () => this.forceAuthRefresh())
  }

  get isSuperAdmin() : boolean {
    return !!this.session?.isSuperAdmin
  }

}
