<div class="settings">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h1 class="m--0"><strong>White Label Settings</strong></h1>
  </div>

  <div class="content__body box box--rounded box--shadow bg--white">
    <mat-tab-group #tabGroup [selectedIndex]="0" (selectedTabChange)="onTabChanged($event)">

      <mat-tab label="General Settings">
        <app-skeletons [visibleSection]="'settings'" [activeTab]="activeTab" *ngIf="loading"></app-skeletons>
        <ng-container *ngIf="!loading">
          <div class="box__header">
            <h2 class="txt--capitalize txt--black m--0"><strong>General Settings</strong></h2>
          </div>
          <form class="general-settings" *ngIf="!loading">
            <div class="box__content box--padding-xxs">
              <div class="row">
                <div class="options col-4">
                  <div class="flex-column">
                    <mat-label class="mb--5">Show/Hide comparisons in grader?</mat-label>
                    <mat-checkbox color="primary" [checked]="(wlDomain|async)?.hideComparisonInGrader"
                      [ngModelOptions]="{standalone: true}" [ngModel]='(wlDomain|async)?.hideComparisonInGrader'
                      (ngModelChange)="wlDomainDocRef.update({hideComparisonInGrader: $event})">
                      <div class="checkbox__content mt--5">
                        <div class="d-flex">
                          <label>{{wlDomain?.hideComparisonInGrader }} Hide comparisons on grader reports.</label>
                        </div>
                      </div>
                    </mat-checkbox>
                  </div>
                </div>

                <div class="options col-4">
                  <div class="flex-column">
                    <mat-label class="mb--5">Disable registration??</mat-label>
                    <mat-checkbox color="primary" [checked]="(wlDomain|async)?.hideSignUpButton" [ngModelOptions]="{standalone: true}"
                      [ngModel]='(wlDomain|async)?.hideSignUpButton'
                      (ngModelChange)="wlDomainDocRef.update({hideSignUpButton: $event})">
                      <div class="checkbox__content mt--5">
                        <div class="d-flex">
                          <label>{{wlDomain?.hideSignUpButton }} Hide sign up button.</label>
                        </div>
                      </div>
                    </mat-checkbox>
                  </div>
                </div>

                <div class="options col-4">
                  <div class="flex-column">
                    <mat-label class="mb--5">Enable Billing Override By Default</mat-label>
                    <mat-checkbox color="primary" [checked]="(wlDomain|async)?.isBillingOverrideEnable"
                      [ngModelOptions]="{standalone: true}" [ngModel]='(wlDomain|async)?.isBillingOverrideEnable'
                      (ngModelChange)="wlDomainDocRef.update({isBillingOverrideEnable: $event})">
                      <div class="checkbox__content mt--5">
                        <div class="d-flex">
                          <label>{{wlDomain?.isBillingOverrideEnable }} Enable Billing Override for all new
                            Subscriptions.</label>
                        </div>
                      </div>
                    </mat-checkbox>
                  </div>
                </div>

                <div class="options col-4">
                  <div class="flex-column">
                    <mat-label class="mb--5">Show/Hide "Contact us" in upgrade?</mat-label>
                    <mat-checkbox color="primary" [checked]="(wlDomain|async)?.hideContactUsButton"
                      [ngModelOptions]="{standalone: true}" [ngModel]='(wlDomain|async)?.hideContactUsButton'
                      (ngModelChange)="wlDomainDocRef.update({hideContactUsButton: $event})">
                      <div class="checkbox__content mt--5">
                        <div class="d-flex">
                          <label>{{wlDomain?.hideContactUsButton }} Hide "Contact us" in a upgrade.</label>
                        </div>
                      </div>
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
      </mat-tab>

      <mat-tab label="Emails | MailerLite">
        <app-skeletons [visibleSection]="'settings'" [activeTab]="activeTab" *ngIf="loading"></app-skeletons>
        <ng-container *ngIf="!loading">
          <div class="box__header">
            <h2 class="txt--capitalize txt--black m--0"><strong>Emails</strong></h2>
          </div>
          <form [formGroup]="formSendgrid" *ngIf="!loading">
            <div class="box__content box--padding-xxs">
              <div class="row mb--20">

                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">From Email</mat-label>
                    <input type="text" formControlName="from_email" class="input-group__field input-group__field--sm">
                  </div>
                </div>
              </div>

              <div class="txt--right">
                <button color="primary" mat-flat-button class="btn btn--lg" (click)="saveSendgrid()">Save</button>
              </div>
            </div>
          </form>

          <div class="box__header">
            <h2 class="txt--capitalize txt--black m--0"><strong>MailerLite Setup</strong></h2>
          </div>
          <form [formGroup]="formMailerLite" *ngIf="!loading">
            <div class="box__content box--padding-xxs">

              <div class="row mb--20">
                <div class="col-12">
                  <div class="flex-column">
                    <mat-label class="mb--5">Api Key</mat-label>
                    <input type="text" formControlName="api_key" class="input-group__field input-group__field--sm"
                      placeholder="xxxxxxxxxxxxxxxx">
                  </div>
                </div>
              </div>
              <div class="txt--right">
                <button color="primary" mat-flat-button class="btn btn--lg" (click)="saveMailerLite()">Save</button>
              </div>
            </div>
          </form>
        </ng-container>
      </mat-tab>

      <mat-tab label="Branding">
        <app-skeletons [visibleSection]="'settings'" [activeTab]="activeTab" *ngIf="loading"></app-skeletons>
        <ng-container *ngIf="!loading">
          <div class="box__header">
            <h2 class="txt--capitalize txt--black m--0"><strong>Branding</strong></h2>
          </div>

          <form [formGroup]="formBranding">
            <div class="box__content box--padding-xxs">

              <div class="row mb--20">
                <div class="col-6">

                  <div class="flex-column">
                    <div class="mb--5"><strong>Login for Dark Background</strong></div>
                    <div class="mb--5">This logo will be used in login, header, external grade, etc.</div>
                    <app-form-input-upload #inputUploadLogin [urlInput]="loginImageUrl" id="login-logo"
                      [imageId]="'login-logo'" [placeId]="placeId" [requirements]="imageRequirements"
                      (url)="handleUrl($event)"></app-form-input-upload>
                    <button *ngIf="loginImageUrl" mat-flat-button color="primary" class="btn btn-icon remove-imagen"
                      matTooltip="Remove" matTooltipPosition="above" matTooltipClass="tooltip tooltip--white"
                      (click)="removeImage('login')">
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </div>
                  <div class="mt--5">
                    <div class="txt--lightgray txt--sm ml--5 d-flex align-items-start">
                      <i class="fas fa-info-circle mr--5"></i>
                      <p class="mb--10 txt--sm">
                        <!--TODO: instead of tooltip, the requirements can be printed here-->
                        <span class="d-block">Minimum Size: 130 x 50</span>
                        <span class="d-block">Maximum Size: 250 x 100</span>
                        <span class="d-block">Formats: JPG, PNG or SVG</span>
                      </p>
                    </div>
                  </div>

                </div>
                <div class="col-6">

                  <div class="flex-column">
                    <div class="mb--5"><strong>Login for Light Background</strong></div>
                    <div class="mb--5">This logo will be used in registration, etc.</div>
                    <app-form-input-upload #inputUploadMainLogo [urlInput]="mainImageUrl" id="main-logo"
                      [imageId]="'main-logo'" [placeId]="placeId" [requirements]="imageRequirements"
                      (url)="handleUrl($event)"></app-form-input-upload>
                    <button *ngIf="mainImageUrl" mat-flat-button color="primary" class="btn btn-icon remove-imagen"
                      matTooltip="Remove" matTooltipPosition="above" matTooltipClass="tooltip tooltip--white"
                      (click)="removeImage('main')">
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </div>
                  <div class="mt--5">
                    <div class="txt--lightgray txt--sm ml--5 d-flex align-items-start">
                      <i class="fas fa-info-circle mr--5"></i>
                      <p class="mb--10 txt--sm">
                        <!--TODO: instead of tooltip, the requirements can be printed here-->
                        <span class="d-block">Minimum Size: 130 x 50</span>
                        <span class="d-block">Maximum Size: 250 x 100</span>
                        <span class="d-block">Formats: JPG, PNG or SVG</span>
                      </p>
                    </div>
                  </div>

                </div>

              </div>

              <div class="row mb--20">
                <div class="col-6">

                  <div class="flex-column">
                    <mat-label class="mb--5">Primary Color</mat-label>
                    <app-color-date-picker (selected)="selectedPrimaryColor($event)" (cleared)="clearedPrimaryColor()"
                      [color]="getColor('primaryColor')" [masterReset]="0"></app-color-date-picker>
                  </div>

                </div>
                <div class="col-6">

                  <div class="flex-column">
                    <mat-label class="mb--5">Secondary Color</mat-label>
                    <app-color-date-picker (selected)="selectedSecondaryColor($event)" (cleared)="clearedSecondaryColor()"
                      [color]="getColor('secondaryColor')" [masterReset]="0"></app-color-date-picker>
                  </div>

                </div>

              </div>

              <div class="row mb--20">
                <div class="col-6">

                  <div class="flex-column">
                    <div class="mb--5"><strong>Favicon</strong></div>
                    <div class="mb--5">This logo will show in browser tab.</div>
                    <app-form-input-upload #inputUploadFav [urlInput]="faviconUrl" [imageId]="'favicon'"
                      [placeId]="placeId" [requirements]="faviconRequirements" (url)="handleUrl($event)">
                    </app-form-input-upload>
                    <button *ngIf="faviconUrl" mat-flat-button color="primary" class="btn btn-icon remove-imagen"
                      matTooltip="Remove" matTooltipPosition="above" matTooltipClass="tooltip tooltip--white"
                      (click)="removeImage('favicon')">
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </div>

                  <div class="mt--5">
                    <div class="txt--lightgray txt--sm ml--5 d-flex align-items-start">
                      <i class="fas fa-info-circle mr--5"></i>
                      <p class="mb--10 txt--sm">
                        <!--TODO: instead of tooltip, the requirements can be printed here-->
                        <span class="d-block">Minimum Size: 24 x 24</span>
                        <span class="d-block">Maximum Size: 32 x 32</span>
                        <span class="d-block">Formats: JPG, PNG or SVG</span>
                      </p>
                    </div>
                  </div>

                </div>

                <div class="col-6">

                  <div class="flex-column">
                    <div class="mb--5"><strong>PDF Logo</strong></div>
                    <div class="mb--5">This logo will be used in export reports.</div>
                    <app-form-input-upload [urlInput]="pdfImageUrl" [imageId]="'pdf-logo'" [placeId]="placeId"
                      [requirements]="pdfRequirements" (url)="handleUrl($event)"></app-form-input-upload>
                    <button *ngIf="pdfImageUrl" mat-flat-button color="primary" class="btn btn-icon remove-imagen"
                      matTooltip="Remove" matTooltipPosition="above" matTooltipClass="tooltip tooltip--white"
                      (click)="removeImage('pdf')">
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </div>

                  <div class="mt--5">
                    <div class="txt--lightgray txt--sm ml--5 d-flex align-items-start">
                      <i class="fas fa-info-circle mr--5"></i>
                      <p class="mb--10 txt--sm">
                        <!--TODO: instead of tooltip, the requirements can be printed here-->
                        <span class="d-block">Minimum Size: 130 x 50</span>
                        <span class="d-block">Maximum Size: 250 x 100</span>
                        <span class="d-block">Formats: PNG</span>
                      </p>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row mb--20">
                <div class="col-6">

                  <div class="flex-column">
                    <mat-label class="mb--5">Login Background</mat-label>
                    <app-color-date-picker (selected)="selectedLoginColor($event)" (cleared)="clearedLoginColor()"
                      [color]="getColor('loginColor')" [masterReset]="0"></app-color-date-picker>
                  </div>

                </div>
              </div>

              <div class="row mb--20">
                <div class="col-6">

                  <div class="flex-column">
                    <mat-label class="mb--5">Google Analytics ID</mat-label>
                    <input type="text" formControlName="analytics" class="input-group__field input-group__field--sm"
                      placeholder="Only enter your GA tracking ID">
                  </div>

                </div>

              </div>

              <div class="row mb--20">
                <div class="col-6">

                  <div class="flex-column">
                    <mat-label class="mb--5">Podium ID</mat-label>
                    <input type="text" formControlName="podium" class="input-group__field input-group__field--sm">
                  </div>

                </div>

                <div class="col-6">

                  <div class="flex-column">
                    <mat-label class="mb--5">Beacon ID</mat-label>
                    <input type="text" formControlName="beacon" class="input-group__field input-group__field--sm">
                  </div>

                </div>
              </div>

              <div class="row mb--20">
                <div class="col-12">

                  <div class="flex-column">
                    <mat-label class="mb--5">Custom Script</mat-label>
                    <textarea formControlName="customScript" class="input-group__field input-group__field--sm"></textarea>
                  </div>

                </div>

              </div>

              <div class="row mb--20">
                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">Main Website</mat-label>
                    <input type="text" formControlName="main_website" class="input-group__field input-group__field--sm">
                  </div>
                </div>

                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">Company Name</mat-label>
                    <input type="text" formControlName="company_name" class="input-group__field input-group__field--sm">
                  </div>
                </div>
              </div>

              <div class="row mb--20">
                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">Company Phone</mat-label>
                    <input type="text" formControlName="company_phone" class="input-group__field input-group__field--sm">
                  </div>
                </div>

                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">Company Address</mat-label>
                    <input type="text" formControlName="company_address"
                      class="input-group__field input-group__field--sm">
                  </div>
                </div>
              </div>

              <div class="row mb--20">
                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">Pricing Url</mat-label>
                    <input type="text" formControlName="pricing_url" class="input-group__field input-group__field--sm">
                  </div>
                </div>
                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">Contact Us</mat-label>
                    <input type="text" formControlName="contact_us" placeholder="Email..."
                      class="input-group__field input-group__field--sm">
                    <div class="txt--red" *ngIf="formBranding?.controls['contact_us']?.errors?.['email']">
                      Enter a valid email.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb--20">

                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">Sign Up Url</mat-label>
                    <input type="text" formControlName="signUp_url" class="input-group__field input-group__field--sm">
                  </div>
                </div>

                <div class="col-6">
                  <div class="flex-column">
                    <mat-label class="mb--5">Grader Alerts Recipient</mat-label>
                    <input type="text" formControlName="grader_email_recipient" class="input-group__field input-group__field--sm"
                      placeholder="Email..."  
                    >
                    <div class="txt--red" *ngIf="formBranding?.controls['grader_email_recipient']?.errors?.['email']">
                      Enter a valid email.
                    </div>
                  </div>
                </div>

              </div>
              <div class="txt--right">
                <button color="primary" mat-flat-button class="btn btn--lg" [disabled]="!formBranding.valid" (click)="saveBranding()">Save</button>
              </div>
            </div>
          </form>
        </ng-container>
      </mat-tab>

      <mat-tab label="Stripe">
        <app-skeletons [visibleSection]="'settings'" [activeTab]="activeTab" *ngIf="isStripeLinkLoading || loading"></app-skeletons>
        <ng-container *ngIf="!isStripeLinkLoading && !loading">
          <div class="box__header">
            <h2 class="txt--capitalize txt--black m--0">
              <strong>Stripe</strong>
            </h2>
          </div>

          <div class="box__content box--padding-xxs">
            <div class="row mb--20">
              <div class="col-6">
                <div class="flex-column">
                  <div *ngIf="!domain?.stripeConnect?.detailsSubmitted">
                    <button color="primary" *ngIf="!domain?.stripeConnect" color="primary" mat-flat-button
                      class="btn btn--lg mt--10 mr--10" (click)="goToStripeAccountLinkOauth()"
                      [disabled]='isStripeLinkLoading'>
                      Connect existing Stripe Account
                      <i *ngIf="isStripeLinkLoading" class="fas fa-spinner fa-pulse"></i>
                    </button>
                    <button color="primary" mat-flat-button class="btn btn--lg mt--10" (click)="goToStripeAccountLink()"
                      [disabled]='isStripeLinkLoading'>
                      Connect Account
                      <i *ngIf="isStripeLinkLoading" class="fas fa-spinner fa-pulse"></i>
                    </button>
                  </div>
                  <div *ngIf="domain?.stripeConnect?.detailsSubmitted">
                    Your account is connected with the Account ID: <strong>{{domain?.stripeConnect?.stripeAccountId}}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </mat-tab>

      <mat-tab label="Payment Method">
        <app-skeletons [visibleSection]="'settings'" [activeTab]="activeTab" *ngIf="loadingCards"></app-skeletons>
        <ng-container *ngIf="!loadingCards">
          <div class="box__header">
            <h2 class="txt--capitalize txt--black m--0"><strong>Payment Method</strong></h2>
          </div>

          <button mat-flat-button class="btn btn-icon add-credit-card" (click)="onAddCard()">
            <em class="pi pi-plus"></em>
            Add Credit Card
          </button>

          <div class="box__content box--padding-xxs">
            <div class="box__content" *ngIf="!loadingCards">
              <div *ngFor="let card of cards; let i = index">
                <div class="box__item pt--0 mb--20 border-bottom">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <div class="d-flex align-items-center">
                        <div class="credit-card">
                          <img src="{{getCardBrandImage(card.brand)}}" alt="{{card.brand}} Logo"/>
                        </div>
                        <div>
                          <p class="mb--5">Credit Card</p>
                          <p class="m--0 txt--black"><strong>**** {{ card?.last4 }}</strong></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <p class="mb--5">Expiration Date</p>
                      <p class="m--0 txt--black"><strong>{{ card?.exp_month }}/{{ card?.exp_year }}</strong></p>
                    </div>
                    <div *ngIf="i === 0" class="col-3 review-response review-response--xs review-response--info">
                      <p class="m--5 txt--center txt--blue txt--uppercase" on>
                        <strong>Default</strong>
                      </p>
                    </div>
                    <div *ngIf="i !== 0" class="col-3 review-response review-response--xs review-response--error cursor--pointer">
                      <p class="m--5 txt--center txt--orange txt--uppercase" 
                         (click)="handleDefaultSource({card: card})">
                        <strong>Set as default</strong>
                      </p>
                    </div>
                    <div *ngIf="i !== 0" class="col-2">
                      <button (click)="handleDeleteSource({card: card})" mat-flat-button color="warn" class="btn btn-icon">
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
      </mat-tab>

    </mat-tab-group>
  </div>

</div>
