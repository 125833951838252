import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { DomainService } from '../../services/domain.service';
import { environment } from '@environment';
import { Router } from '@angular/router';
import { SnackbarService } from '../../services/snackbar.service';
import { WhiteLabelService } from '../../services/white-label.service';

import * as _ from 'lodash';
import { EmailerService } from 'src/app/services/emailer.service';
import { AuthUrls } from 'src/app/constants/auth';

@Component({
  selector: 'app-login',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  passwordProgress = false;
  hide: boolean;
  env;

  constructor(
    public domainService: DomainService,
    private emailerService: EmailerService,
    private auth: AuthService,
    private router: Router,
    private snack: SnackbarService,
    public wl: WhiteLabelService
  ) {
    this.forgotForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  async ngOnInit() {
    await this.auth.afAuth.signOut();
    this.env = environment;
  }

  forgotPassword() {
    this.passwordProgress = true;
    this.emailerService.sendResetPasswordMail(this.email?.value).subscribe(
      () => {
        this.passwordProgress = false;
        this.router.navigateByUrl(AuthUrls.LOGIN);
        this.snack.openSuccess(`A reset password email has been sent to ${this.email?.value}`, 4000);
      }, e => {
        this.snack.openError(`${e.message}`, 4000);
        this.passwordProgress = false;
      });
  }


  get email() {
    return this.forgotForm.get('email');
  }

  goToTermsOfService(): void {
    this.wl.getDynamicWhiteLabel().then((data: any) => {
      const { content } = data;
      const link = _.get(content, 'terms_service_link') || null;
      link && window.open(link, '_blank') || window.open(this.router.createUrlTree(['/text-reader/', 'terms-service']).toString(), '_blank');
    });
  }

  goToPrivacyPolicy(): void {
    this.wl.getDynamicWhiteLabel().then((data: any) => {
      const { content } = data;
      const link = _.get(content, 'privacy_policy_link') || null;
      link && window.open(link, '_blank') || window.open(this.router.createUrlTree(['/text-reader/', 'privacy-policy']).toString(), '_blank');
    });
  }
  goToPaymentPolicy(): void {
    this.wl.getDynamicWhiteLabel().then((data: any) => {
      const { content } = data;
      const link = _.get(content, 'payment_policy_link') || null;
      link && window.open(link, '_blank') || window.open(this.router.createUrlTree(['/text-reader/', 'payment-policy']).toString(), '_blank');
    });
  }


}
