// dep
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash'

// app
import User from 'src/app/constants/firestore/user';
import { ImageRequirement } from 'src/app/constants/google-media';

import { DomainService } from 'src/app/services/domain.service';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
// TODO: enviroment.default is explicitely choosen here, is ok to not use @environment?
import { environment } from 'src/environments/environment.default';
import { GroupService } from 'src/app/services/group.service';
import { ModalService } from 'src/app/services/modal.service';
import { UpdateCardComponent } from './update-card/update-card.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls:  ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public domainId: string;
  public formSendgrid: FormGroup;
  public formMailerLite: FormGroup;
  public formBranding: FormGroup;
  public user: User;
  public placeId: string;
  public wlDomain;
  public wlDomainDocRef;
  public domain;
  // TODO: Unused, remove
  // public mailerData = { api_key: ''}; 
  // public brandingData = {
  //   analytics: '',
  //   companyName: '',
  //   companyPhone: '',
  //   companyAddress: '',
  //   customScript: '',
  //   podium: '',
  //   beacon: '',
  //   favicon: '',
  //   loginLogo: '',
  //   mainLogo: '',
  //   pdfLogo: '',
  //   mainWebsite: '',
  //   pricing_url: '',
  //   primaryColor: '',
  //   secondaryColor: '',
  //   loginColor: '',
  //   contact_us: '',
  //   signUp_url: '',
  //   grader_email_recipient: '',
  // };
  public loginImageUrl: string;
  public mainImageUrl: string;
  public pdfImageUrl: string;
  public faviconUrl: string;
  public imageRequirements: ImageRequirement = {
    accepts: '.png, .svg',
    type: ['image/png', 'image/svg+xml'],
    min_width: 130,
    min_height: 50,
    max_width: 250,
    max_height: 100,
    min_size: 10240
  };
  public faviconRequirements: ImageRequirement = {
    accepts: 'image/x-png,image/gif,image/jpeg, image/vnd.microsoft.icon',
    type: ['image/png', 'image/vnd.microsoft.icon, image/x-icon'],
    min_width: 32,
    min_height: 32,
    max_width: 48,
    max_height: 48,
    min_size: 10240
  };
  public pdfRequirements: ImageRequirement = {
    accepts: '.png',
    type: ['image/png'],
    min_width: 130,
    min_height: 50,
    max_width: 250,
    max_height: 100,
    min_size: 10240
  };
  public searchDomain$: any;
  public loading = true;
  public isStripeLinkLoading = false;
  /** If true, show cards list as an skeleton */
  public loadingCards = false; 
  public cards: any[];
  public group: any;
  public activeTab = 0;

  constructor(
    public fb: FormBuilder,
    public domainService: DomainService,
    public auth: AuthService,
    public wl: WhiteLabelService,
    private snack: SnackbarService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private groupService: GroupService,
    public modalService: ModalService,
    private paymentService: PaymentsService
  ) {
    this.setUp();
  }

  ngOnInit(): void {
    sessionStorage.removeItem('goGroups');
    sessionStorage.removeItem('gid');
    this.wlDomain       = this.domainService.getByDocId(this.wl.slugDomain).valueChanges();
    this.wlDomainDocRef = this.domainService.getByDocId(this.wl.slugDomain);

    if (this.domainService.domain$.value) {
      this.loading = true;
      this.setDomain(this.domainService.domain$.value);
    } else {
      this.loading = true;
      this.searchDomain$ = this.domainService.searchIndex();
      this.searchDomain$.subscribe(domain => {
        this.domainService.domain$.next(domain);
        this.setDomain(this.domainService.domain$.value);
      });
    }

    this.fetchCards();

    this.groupService.getByDocId(this.user.gid).subscribe(group => {
      this.group = group;
    });
  }

  private setUp(): void {
    this.formSendgrid = this.fb.group({
      from_email: [''],
    });

    this.formMailerLite = this.fb.group({
      api_key: ['']
    })

    this.formBranding = this.fb.group({
      loginLogo: [''],
      mainLogo: [''],
      pdfLogo: [''],
      favicon: [''],
      primaryColor: [''],
      secondaryColor: [''],
      loginColor: [''],
      analytics: [''],
      podium: [''],
      beacon: [''],
      customScript: [''],
      main_website: [''],
      company_name: [''],
      company_phone: [''],
      company_address: [''],
      pricing_url: [''],
      contact_us: ['', [Validators.email]],
      signUp_url: [''],
      grader_email_recipient: ['', [Validators.email]],
    });

    this.user = this.auth.session;
    this.placeId = _.get(this.user, 'gid');
    this.domainId = this.wl.slugDomain;
  }

  async updateDomain(domain: object, value: string) {
    const params = (['sendgrid', 'mailerlite', 'branding'].includes(value) ?
                    { [value]: domain } : 
                    domain) 
    await this.domainService.updateDomain(this.domainId, params)
    this.snack.openSuccess('Successful Update!', 4000)
  }

  private setDomain(domain) {
    this.domain = domain;
    this.setSendgrid(_.get(this.domain, 'sendgrid'));
    this.setMailerLite({..._.get(this.domain, 'mailerlite')});
    this.setBranding(_.get(this.domain, 'branding'));
    this.loading = false;
  }

  setSendgrid(sendgrid: string): void {
    const from   = _.get(sendgrid, 'from_email', null);
    this.formSendgrid.get('from_email').setValue(from);
  }

  async saveSendgrid() {
    const { from_email } = this.formSendgrid.getRawValue();
    await this.updateDomain({ from_email }, 'sendgrid')
  }

  setMailerLite(mailerlite: any): void {
    const api_key   = _.get(mailerlite, 'api_key', null)
    // this.mailerData = { api_key } // TODO: Unused, remove.
    this.formMailerLite.get('api_key').setValue(api_key)
  }

  async saveMailerLite() {
    const { api_key } = this.formMailerLite.getRawValue();
    const params = _.omitBy({ api_key }, _.isNil);
    await this.updateDomain(params, 'mailerlite')
  }

  setBranding(branding: any): void {
    const b = {
      loginLogo: _.get(branding, 'loginLogo', null),
      mainLogo: _.get(branding, 'mainLogo', null),
      pdfLogo: _.get(branding, 'pdfLogo', null),
      favicon: _.get(branding, 'favicon', null),
      primaryColor: _.get(branding, 'primaryColor', null),
      secondaryColor: _.get(branding, 'secondaryColor', null),
      loginColor: _.get(branding, 'loginColor', null),
      analytics: _.get(branding, 'analytics', null),
      podium: _.get(branding, 'podium', null),
      beacon: _.get(branding, 'beacon', null),
      customScript: _.get(branding, 'customScript', null),
      mainWebsite: _.get(branding, 'main_website', null),
      companyName: _.get(branding, 'company_name', null),
      companyPhone: _.get(branding, 'company_phone', null),
      companyAddress: _.get(branding, 'company_address', null),
      pricing_url: _.get(branding, 'pricing_url', null),
      contact_us: _.get(branding, 'contact_us', null),
      signUp_url: _.get(branding, 'signUp_url', null),
      grader_email_recipient: _.get(branding, 'grader_email_recipient', null)
    };
    this.formBranding.get('loginLogo').setValue(b.loginLogo);
    this.loginImageUrl = b.loginLogo;
    this.formBranding.get('mainLogo').setValue(b.mainLogo);
    this.mainImageUrl = b.mainLogo;
    this.formBranding.get('pdfLogo').setValue(b.pdfLogo);
    this.pdfImageUrl = b.pdfLogo;
    this.formBranding.get('favicon').setValue(b.favicon);
    this.faviconUrl = b.favicon;
    this.formBranding.get('primaryColor').setValue(b.primaryColor);
    this.formBranding.get('secondaryColor').setValue(b.secondaryColor);
    this.formBranding.get('loginColor').setValue(b.loginColor);
    this.formBranding.get('analytics').setValue(b.analytics);
    this.formBranding.get('podium').setValue(b.podium);
    this.formBranding.get('beacon').setValue(b.beacon);
    this.formBranding.get('customScript').setValue(b.customScript);
    this.formBranding.get('main_website').setValue(b.mainWebsite);
    this.formBranding.get('company_name').setValue(b.companyName);
    this.formBranding.get('company_phone').setValue(b.companyPhone);
    this.formBranding.get('company_address').setValue(b.companyAddress);
    this.formBranding.get('pricing_url').setValue(b.pricing_url);
    this.formBranding.get('contact_us').setValue(b.contact_us);
    this.formBranding.get('signUp_url').setValue(b.signUp_url);
    this.formBranding.get('grader_email_recipient').setValue(b.grader_email_recipient);
  }

  async saveBranding() {
    const { analytics, company_name, company_phone, company_address, customScript, podium, beacon, favicon, loginLogo, mainLogo, pdfLogo, main_website, pricing_url, primaryColor, secondaryColor, loginColor, contact_us, signUp_url, grader_email_recipient } = this.formBranding.getRawValue();
    const params = { analytics, company_name, company_phone, company_address,  customScript, podium, beacon, favicon, loginLogo, mainLogo, pdfLogo, main_website, pricing_url, primaryColor, secondaryColor, loginColor, contact_us, signUp_url, grader_email_recipient };
    await this.updateDomain(params, 'branding')
  }

  handleUrl($event: {url: string, mediaFormat: string}): void {
    if ($event) {
      if (_.includes($event.url, 'login-logo')) {
        this.loginImageUrl = $event.url;
        this.formBranding.get('loginLogo').setValue($event.url);
      } else if (_.includes($event.url, 'main-logo')) {
        this.mainImageUrl = $event.url;
        this.formBranding.get('mainLogo').setValue($event.url);
      } else if (_.includes($event.url, 'favicon')) {
        this.faviconUrl = $event.url;
        this.formBranding.get('favicon').setValue($event.url);
      } else if (_.includes($event.url, 'pdf-logo')) {
        this.pdfImageUrl = $event.url;
        this.formBranding.get('pdfLogo').setValue($event.url);
      }
      this.cdr.detectChanges();
    }
  }

  selectedPrimaryColor($event) {
    this.formBranding.get('primaryColor').setValue($event);
  }

  clearedPrimaryColor() {
    this.formBranding.get('primaryColor').setValue(null);
  }

  getColor(color: string): string {
    return this.formBranding.get(color).value;
  }

  selectedSecondaryColor($event) {
    this.formBranding.get('secondaryColor').setValue($event);
  }

  clearedSecondaryColor() {
    this.formBranding.get('secondaryColor').setValue(null);
  }

  selectedLoginColor($event) {
    this.formBranding.get('loginColor').setValue($event);
  }

  clearedLoginColor() {
    this.formBranding.get('loginColor').setValue(null);
  }

  removeImage(logo: string): void {
    if ('login' === logo) {
      this.loginImageUrl = null;
      this.formBranding.get('loginLogo').setValue(null);
    } else if ('main' === logo) {
      this.mainImageUrl = null;
      this.formBranding.get('mainLogo').setValue(null);
    } else if ('favicon' === logo) {
      this.faviconUrl = null;
      this.formBranding.get('favicon').setValue(null);
    } else if ('pdf' === logo) {
      this.pdfImageUrl = null;
      this.formBranding.get('pdfLogo').setValue(null);
    }
  }

  async goToStripeAccountLink(): Promise<void> {
    try {
      this.isStripeLinkLoading = true
      const accountLink = await this.fetchStripeAccountLink()
      window.location.href = `${accountLink?.data?.url}`;
    } catch (e) {
      this.snack.openError(e?.error?.detail?.message, 2000);
    } finally {
      this.isStripeLinkLoading = false
    }
  }

  async goToStripeAccountLinkOauth(): Promise<void> {
    try {
      this.isStripeLinkLoading = true
      console.log(this.domain);
      const accountLink = this.getStripeAccountLinkOauth(this.placeId, this.domainId);
      window.location.href = accountLink;
    } catch (e) {
      this.snack.openError(e?.error?.detail?.message, 2000);
    } finally {
      this.isStripeLinkLoading = false
    }
  }

  private fetchStripeAccountLink() : Promise<any> {
    return this.http.post(`${environment.billingApiUrl}/account/account_link`, {}).toPromise()
  }

  /*
    This will end calling billing-api /api/account/account_link/oauth, which must be
    an url configured on Stripe (see that endpoint documentation)
  */
  private getStripeAccountLinkOauth(gid : string, domainId : string) {
    return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_JTgcLEZHrSDPNC8yzIsGjgJKqyjfXrP1&scope=read_write&state=${domainId},${gid}`
  }

  handleDefaultSource(card): void {
    this.modalService.openConfirmModal(
      "Set as default",
      "Are you sure you want set this Card as the Default?",
      confirm => {
        if (confirm)
          this.applyDefaultCard(card.card)
      },
      null,
      "Confirm",
      false,
      null,
      false
    );
  }

  handleDeleteSource(card): void {
    this.modalService.openConfirmModal(
      "Delete card",
      "Are you sure you want to Delete this Card?",
      confirm => {
        if (confirm)
          this.applyDeleteSource(card.card);
      },
      null,
      "Confirm",
      false,
      null,
      false
    );
  }

  async applyDefaultCard(card) {
    await this.paymentService.setDefaultCard(card.id)
    this.snack.openSuccess('Set as default Credit Card succesfully', 3000)
    this.fetchCards();
  }

  async applyDeleteSource(card) {
    await this.paymentService.deleteCard(card.id).toPromise()
    this.snack.openSuccess('Credit Card deleted succesfully', 3000)
    this.fetchCards()
  }


  onAddCard(): void {
    const customerId = this.group.stripeCustomerId

    this.modalService.openGenericModal(UpdateCardComponent, {
      title: 'Add new Credit Card',
      add: true,
      customerId,
      exist: true
    }, res => {
      if (res) 
        this.fetchCards()
    });
  }

  private async fetchCards() {
    if(!this.loadingCards)
      try {
        this.loadingCards = true
        this.cards = (await this.paymentService.fetchCards() as any).data 
      } finally {
        this.loadingCards = false
      }
  }

  onTabChanged($event) {
    this.activeTab = $event.index;
  }

  getCardBrandImage(brand : string) : string {
    return PaymentsService.getCardBrandImage(brand)
  }

}
