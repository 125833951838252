import {Component, Inject, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '@environment';
import {first, map, take} from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthService } from '../../services/auth.service'
import { SnackbarService } from '../../services/snackbar.service';
import { WhiteLabelService } from "../../services/white-label.service";
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';
import { GroupService } from 'src/app/services/group.service';
import { AuthUrls }  from "../../constants/auth"
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginProgress = false;
  hide?: boolean
  env: any;

  constructor(
    private auth: AuthService,
    private router: Router,
    private snack: SnackbarService,
    public wl: WhiteLabelService,
    private userService: UserService,
    private modalService: ModalService,
    private groupService: GroupService,
    @Inject(DOCUMENT) 
    private document: Document
  ) {
    sessionStorage.removeItem("domain");
    this.auth.afAuth.authState.pipe(first()).toPromise().then(isSignedIn => {

      if (isSignedIn && this.auth.session?.role && this.auth.session.email !== 'external-grade@maplabs.com') {
        
        this.userService.updateForLogin(this.auth.session.gid, this.auth.session.uid)?.pipe(take(1)).subscribe();

        if (this.auth.session.isMasterAdmin || this.auth.session.isSuperAdmin) {
          this.groupService.getByDocId(this.auth.session.gid).subscribe( res => {
            sessionStorage.domain = res.domain;
            this.wl.getDynamicWhiteLabel().then(data => {
              const user = {...this.auth.session};
              user.branding = data?.branding;
              this.auth.setSession(user);
              this.setBranding(data);
              const url = sessionStorage?.goGroups || AuthUrls.SUCCESS;
              this.router.navigate([url]);
              sessionStorage.removeItem("goGroups");
              this.snack.openSuccess('You are being automatically logged in using your existing session!', 3000);
            });
          });
        } else {
          this.snack.openError('The account does not have permissions!', 3000);
        }
      } else {
        this.auth.signOut();
      }
    });


    this.loginForm = new FormGroup({
      email:    new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });

  }

  async ngOnInit() {
    this.env = environment;
    this.hide = true;
  }

  signInWithEmailAndPassword($event: any) {
    $event.preventDefault();
    const {email, password} = this.loginForm.value;
    this.loginProgress = true;
    this.auth.signInWithEmailAndPassword(email, password).then(res => {
      this.groupService.getByDocId(this.auth.session.gid).subscribe( res => {
        sessionStorage.domain = res.domain;
        this.wl.getDynamicWhiteLabel().then((data: any) => {
          const user = {...this.auth.session};
          user.branding = data?.branding;
          this.auth.setSession(user);
          this.setBranding(data);
          this.loginProgress = false
        });
      });
    }).catch(err => {
      this.loginProgress = false;
      if (err.message === 'registration domain is not the current domain') {
        this.modalService.openConfirmModal(
          'You can’t login here…',
          'You appear to have a valid account but you are logging-in to the wrong domain. Please visit the correct site and try again.',
          _ => null,
          null,
          'Ok'
        );
        return;
      }
      this.snack.openError('The user you entered is incorrect or not registered!', 3000);
    });
  }

  signInWithGoogle(event: any): void {
    event.preventDefault();
    this.loginProgress = true;
    this.auth.googleLogin().then((res: any) => {
      if (res) {
        this.groupService.getByDocId(this.auth.session.gid).subscribe( res => {
          sessionStorage.domain = res.domain;
          this.wl.getDynamicWhiteLabel().then((data: any) => {
            const user = {...this.auth.session};
            user.branding = data?.branding;
            this.auth.setSession(user);
            this.setBranding(data);
            this.loginProgress = false
          });
        });
      }
    }).catch((err: any) => {
      this.loginProgress = false;
      if (err.message === 'registration domain is not the current domain') {
        this.modalService.openConfirmModal(
          'You can’t login here…',
          'You appear to have a valid account but you are logging-in to the wrong domain. Please visit the correct site and try again.',
          (_: any) => null,
          null,
          'Ok'
        );
        return;
      }
      this.snack.openError('This account could not be found or the popup was closed. You need to register first', 3000);
    });
  }

  goToTermsOfService(): void {
    this.wl.getDynamicWhiteLabel().then(domain => {
      const {content} = domain;
      const link = _.get(content, 'terms_service_link') || null;
      link && window.open(link, '_blank') || window.open(this.router.createUrlTree(['/text-reader/', 'terms-service']).toString(), '_blank');
    });
  }

  goToPrivacyPolicy(): void {
    this.wl.getDynamicWhiteLabel().then(domain => {
      const {content} = domain;
      const link = _.get(content, 'privacy_policy_link') || null;
      link && window.open(link, '_blank') || window.open(this.router.createUrlTree(['/text-reader/', 'privacy-policy']).toString(), '_blank');
    });
  }

  goToPaymentPolicy(): void {
    this.wl.getDynamicWhiteLabel().then(domain => {
      const {content} = domain;
      const link = _.get(content, 'payment_policy_link') || null;
      link && window.open(link, '_blank') || window.open(this.router.createUrlTree(['/text-reader/', 'payment-policy']).toString(), '_blank');
    });
  }

  setBranding(data) {
    environment.apiUrl                  = environment?.apiUrl;
    environment.branding                = data?.branding;
    environment.hideComparisonInGrader  = data?.hideComparisonInGrader;
    environment.hideSignUpButton        = data?.hideSignUpButton;

    document.getElementById('favicon').setAttribute('href', data?.branding?.favicon || '/assets/images/favicon-wl/favicon.ico')
  }
}

